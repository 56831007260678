import React from 'react'
import { SEO } from '../components'
import { AboutUs } from '../routes'

const GetStartedPage = () => {
  return (
    <>
      <SEO title={'About Us'} withoutTitleTemplate description={''} />
      <AboutUs />
    </>
  )
}

export default GetStartedPage
